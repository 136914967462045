import { AvailableFileModel, DataExtensionField } from "models";
import { DataExtensionAttributes, DataExtensionColumn } from "../salesforce.types";

export const getInitialDataExtensionColumns = (
  files: AvailableFileModel[],
  fields: DataExtensionField[],
): DataExtensionAttributes[] => {
  return files.map((file) => {
    const columns: DataExtensionColumn[] = fields.map((field) => ({
      field: field.Name,
      column: "",
      isRequired: field.IsRequired === "true",
    }));

    return { fileId: file.fileId, columns: columns };
  });
};
