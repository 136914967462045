import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, SelectOption, useDidMount, useDidUpdate } from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { essentialColumn } from "pages/files/list/files-list.constants";
import { FileTabButton } from "components/ui/file-tabs";
import { TabOption } from "components";
import { SalesforceType } from "../salesforce.types";
import { haveCustomAttributesError } from "../salesforce.constants";
import { getInitialListsColumns } from "./lists-columns.constants";

import styles from "../salesforce.module.scss";

interface Props {
  endpointOrdinal: number;
}

export const ListsColumns: React.FC<Props> = ({ endpointOrdinal }) => {
  const [editedFileIndex, setEditedFileIndex] = useState<number>(0);

  const { values, errors, setFieldValue } = useFormikContext<InitialValues>();

  const salesforceValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | SalesforceType
    | undefined;
  const { custom_attributes } = salesforceValues || {};

  const getFieldName = (index: number) => (suffix: string) =>
    `endpoints[${endpointOrdinal}].connector.properties.custom_attributes[${index}]${suffix}`;

  useDidMount(() => {
    if (custom_attributes?.length === 0) {
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties.custom_attributes`,
        getInitialListsColumns(values.attachedFiles),
      );
    }
  });

  useDidUpdate(() => {
    // set new values when files change
    setEditedFileIndex(0);
    setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.custom_attributes`,
      getInitialListsColumns(values.attachedFiles),
    );
  }, [values.attachedFiles]);

  const fileTabOptions: TabOption<number>[] = useMemo(() => {
    return values.attachedFiles.map((file, index) => ({ label: file.fileName, value: index }));
  }, [values.attachedFiles]);

  const fileColumns: SelectOption<string>[] = useMemo(() => {
    if (!values.attachedFiles?.[editedFileIndex]) return [];

    const emailKey = essentialColumn.toLowerCase();
    const emailColumn: SelectOption<string> = { label: emailKey, value: emailKey };
    const columns: SelectOption<string>[] = values.attachedFiles[editedFileIndex].columns.map(
      (column) => ({
        label: column,
        value: column,
      }),
    );

    return [emailColumn, ...columns];
  }, [values.attachedFiles, editedFileIndex]);

  const haveFilesSelected = values.attachedFiles.length > 0;

  return (
    <div>
      <p className={styles.mainLabel}>Lists columns</p>
      {!haveFilesSelected && (
        <p className={styles.description}>You need to select a file to set a columns</p>
      )}
      {haveFilesSelected && (
        <>
          <div className={styles.tabsWrapper}>
            {fileTabOptions.map((option, index) => {
              const haveError = haveCustomAttributesError(errors, endpointOrdinal, index);

              return (
                <FileTabButton
                  key={option.value}
                  selected={option.value === editedFileIndex}
                  label={option.label}
                  isValid={!haveError}
                  onClick={() => setEditedFileIndex(index)}
                />
              );
            })}
          </div>

          <div className={styles.content}>
            <div className={styles.inputWrapper}>
              <div className={styles.inputLabel}>
                <Label text="Subscriber key column" isInputLabel isRequired />
                <p className={styles.projectDescription}>Select "subscriber key"</p>
              </div>
              <FormField
                type="select"
                label=""
                required
                inputSize="small"
                placeholder="Select subscriber key column"
                name={getFieldName(editedFileIndex)(".column")}
                options={fileColumns}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
