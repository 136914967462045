import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import {
  FormField,
  Label,
  Loader,
  SelectOption,
  useDidMount,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { essentialColumn } from "pages/files/list/files-list.constants";
import { DataExtensionField } from "models";
import { FileTabButton } from "components/ui/file-tabs";
import { TabOption } from "components";
import { SalesforceType } from "../salesforce.types";
import { haveCustomAttributesError } from "../salesforce.constants";
import { getInitialDataExtensionColumns } from "./data-extension-columns.constants";

import styles from "../salesforce.module.scss";

interface Props {
  endpointOrdinal: number;
  fields: DataExtensionField[];
  fieldsLoading: boolean;
}

export const DataExtensionColumns: React.FC<Props> = ({
  endpointOrdinal,
  fields,
  fieldsLoading,
}) => {
  const [editedFileIndex, setEditedFileIndex] = useState<number>(0);

  const { values, errors, setFieldValue } = useFormikContext<InitialValues>();

  const salesforceValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | SalesforceType
    | undefined;
  const { custom_attributes } = salesforceValues || {};

  const getFieldName = (index: number) => (suffix: string) =>
    `endpoints[${endpointOrdinal}].connector.properties.custom_attributes[${index}]${suffix}`;

  useDidMount(() => {
    if (custom_attributes?.length === 0) {
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties.custom_attributes`,
        getInitialDataExtensionColumns(values.attachedFiles, fields),
      );
    }
  });

  useDidUpdate(() => {
    // set new values when files change
    setEditedFileIndex(0);
    setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.custom_attributes`,
      getInitialDataExtensionColumns(values.attachedFiles, fields),
    );
  }, [values.attachedFiles]);

  const fileTabOptions: TabOption<number>[] = useMemo(() => {
    return values.attachedFiles.map((file, index) => ({ label: file.fileName, value: index }));
  }, [values.attachedFiles]);

  const fileColumns: SelectOption<string>[] = useMemo(() => {
    if (!values.attachedFiles?.[editedFileIndex]) return [];

    const emailKey = essentialColumn.toLowerCase();
    const emailColumn: SelectOption<string> = { label: emailKey, value: emailKey };
    const columns: SelectOption<string>[] = values.attachedFiles?.[editedFileIndex]?.columns.map(
      (column) => ({
        label: column,
        value: column,
      }),
    );

    return [emailColumn, ...columns];
  }, [values.attachedFiles, editedFileIndex]);

  const haveFilesSelected = values.attachedFiles.length > 0;

  return (
    <div>
      <p className={styles.mainLabel}>Data extension columns</p>
      <p className={styles.description}>Select columns for each file</p>
      {!haveFilesSelected && (
        <p className={styles.description}>You need to select a file to set a columns</p>
      )}
      {haveFilesSelected && (
        <>
          {fieldsLoading && <Loader type="clip-loader" />}
          {!fieldsLoading && fields.length === 0 && (
            <p className={styles.description}>No fields found for this data extension</p>
          )}
          {!fieldsLoading && fields.length > 0 && (
            <div>
              <div className={styles.tabsWrapper}>
                {fileTabOptions.map((option, index) => {
                  const haveError = haveCustomAttributesError(errors, endpointOrdinal, index);

                  return (
                    <FileTabButton
                      key={option.value}
                      selected={option.value === editedFileIndex}
                      label={option.label}
                      isValid={!haveError}
                      onClick={() => setEditedFileIndex(index)}
                    />
                  );
                })}
              </div>

              <div className={styles.content}>
                {fields.map((field, index) => {
                  return (
                    <div key={field.Name} className={styles.inputWrapper}>
                      <div className={styles.inputLabel}>
                        <Label
                          text={field.Name}
                          isInputLabel
                          isRequired={field.IsRequired === "true"}
                        />
                        <p className={styles.projectDescription}>Select "{field.Name}" column</p>
                      </div>
                      <FormField
                        type="select"
                        label=""
                        required
                        inputSize="small"
                        placeholder="Select column"
                        name={getFieldName(editedFileIndex)(`.columns[${index}].column`)}
                        options={fileColumns}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
