import { IterableIntegration } from "models";
import { IntegrationData, IterableData } from "api";

export const initialValues = (item: IterableIntegration): IntegrationData<IterableData> => ({
  apiType: "iterable",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
  },
});
