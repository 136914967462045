import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { SalesforceIntegration } from "models";
import { IntegrationData, SalesforceData, updateIntegration } from "api";
import { salesforceSchema } from "../salesforce-table.constants";
import { initialValues } from "./update-salesforce.constants";

import styles from "../salesforce-table.module.scss";

interface UpdateSalesforceProps {
  isOpen: boolean;
  item: SalesforceIntegration;
  handleClose: () => void;
  onSuccess: () => void;
}

export const UpdateSalesforce: React.FC<UpdateSalesforceProps> = ({
  isOpen,
  item,
  handleClose,
  onSuccess,
}) => {
  const { submit, onCallSuccess, onCallError } = useCall(updateIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Salesforce config updated.",
      "You can now use this updated config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with updating the Salesforce integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<SalesforceData>) => {
    await submit({ data: values, params: { id: item.id }, queryParams: "?apiType=salesforce" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.header}>
        <h1>Update Salesforce config</h1>
        <p>Update Salesforce config. This can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues(item)}
          onSubmit={handleSubmit}
          validationSchema={salesforceSchema}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                type="text"
                name="name"
                label="Config name"
                placeholder="Provide a config name"
              />
              <FormField
                type="text"
                name="properties.clientId"
                label="Client ID"
                placeholder="Client ID"
              />
              <FormField
                type="text"
                name="properties.clientSecret"
                label="Client Secret"
                placeholder="Client Secret"
              />
              <FormField type="text" name="properties.origin" label="Origin" placeholder="Origin" />
              <FormField
                type="text"
                name="properties.authOrigin"
                label="Auth Origin"
                placeholder="Auth Origin"
              />
              <FormField
                type="text"
                name="properties.soapOrigin"
                label="Soap Origin"
                placeholder="Soap Origin"
              />

              <div className={styles.buttons}>
                <Button appearance="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
