import { RemarketyIntegration } from "models";
import { IntegrationData, RemarketyData } from "api";

export const initialValues = (item: RemarketyIntegration): IntegrationData<RemarketyData> => ({
  apiType: "remarkety",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
    storeId: item?.properties.storeId || "",
  },
});
