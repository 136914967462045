import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { createIntegration, IntegrationData, OmnisendData } from "api";
import { omnisendSchema } from "../omnisend-table.constants";
import { initialValues } from "./create-omnisend.constants";

import styles from "../omnisend-table.module.scss";

interface CreateOmnisendProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const CreateOmnisend: React.FC<CreateOmnisendProps> = ({
  isOpen,
  handleClose,
  onSuccess,
}) => {
  const { submit, onCallSuccess, onCallError } = useCall(createIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Omnisend config created.",
      "You can now use this config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with creating Omnisend integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<OmnisendData>) => {
    await submit({ data: values, queryParams: "?apiType=omnisend" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.header}>
        <h2>Create Omnisend config</h2>
        <p>Create config for Omnisend. This config can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={omnisendSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                type="text"
                name="name"
                label="Config name"
                placeholder="Provide a config name"
              />
              <FormField
                type="text"
                name="properties.apiKey"
                label="API Key"
                placeholder="API Key"
              />

              <div className={styles.buttons}>
                <Button appearance="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
