import { IntegrationData, MarketoData } from "api";

export const initialValues: IntegrationData<MarketoData> = {
  apiType: "marketo",
  name: "",
  properties: {
    endpoint: "",
    clientSecret: "",
    clientId: "",
  },
};
