import { object, number, string, SchemaOf, mixed } from "yup";

import { EndpointAPITypes, EndpointAPITypesKeys } from "models";
import { requiredFieldText } from "constants/form.constants";
import { ApiTypes, EndpointAPIType, EndpointType } from "./endpoint.types";
import { ConnectorsValidations, EndpointAPIContentType } from "./endpoint-api-dispatcher";

export const apiTypeOptions: EndpointAPIType[] = Object.entries(EndpointAPITypes).map(
  ([key, value]) => ({
    value: key as EndpointAPITypesKeys,
    label: value,
  }),
);

const apiTypes = Object.keys(EndpointAPITypes) as ApiTypes;

export const initialEndpointsProperties: Record<EndpointAPITypesKeys, EndpointAPIContentType> = {
  restApi: { httpMethod: "GET", targetUrl: "" },
  blueshift: { apiKey: "", custom_attributes: "" },
  salesforce: {
    clientId: "",
    clientSecret: "",
    origin: "",
    authOrigin: "",
    soapOrigin: "",
    listId: "",
    custom_attributes: [],
    type: "dataExtension",
  },
  iterable: { apiKey: "", listId: "" },
  omnisend: { apiKey: "" },
  remarkety: { storeId: "", apiKey: "" },
  greenarrow: { apiKey: "", apiUrl: "", listId: "" },
  useInsider: { apiKey: "", partnerName: "" },
  getResponse: { apiKey: "", campaignId: "" },
  marketo: {
    endpoint: "",
    clientId: "",
    clientSecret: "",
    formId: 0,
    files: [],
  },
};

export const endpointSchema: SchemaOf<EndpointType> = object({
  name: string()
    .matches(/^(?!\s+$)/, "Name should contain not just spaces")
    .min(3, "Name is too short")
    .required(requiredFieldText),
  webhookId: number()
    .min(1, requiredFieldText)
    .typeError(requiredFieldText)
    .required(requiredFieldText),
  proxyValue: string()
    .matches(/(http|https):\/\/(\S+):(\S+)@(\S+):(\d+)/gm, "Proxy should match the proper format")
    .min(3, "Proxy is too short")
    .nullable(),
  proxyId: string().nullable(),
  jobId: string(),
  connector: object<any>({
    apiType: mixed<EndpointAPITypesKeys>().oneOf(apiTypes).required(requiredFieldText),
    properties: object<any>().when(
      "apiType",
      (apiType: EndpointAPITypesKeys) => ConnectorsValidations[apiType],
    ),
  }),
});

export const editBatchBtnText = "Save";
export const createBatchBtnText = "Create Batch";
