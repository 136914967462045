import { array, boolean, object, SchemaOf, string } from "yup";
import { FormikErrors } from "formik";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { requiredFieldText } from "constants/form.constants";
import {
  DataExtensionAttributes,
  ListAttributes,
  SalesforceType,
  DataExtensionColumn,
} from "./salesforce.types";

export const haveCustomAttributesError = (
  errors: FormikErrors<InitialValues>,
  endpointOrdinal: number,
  fileIndex: number,
) => {
  const endpointObject = errors?.endpoints?.[endpointOrdinal];

  if (typeof endpointObject === "string") return false;

  const properties = endpointObject?.connector?.properties as unknown as SalesforceType;

  return typeof properties?.custom_attributes?.[fileIndex] !== "undefined";
};

const listsColumnsSchema: SchemaOf<ListAttributes> = object({
  fileId: string().required("File ID is required"),
  column: string().required("Column is required"),
});

const dataExtensionColumn: SchemaOf<DataExtensionColumn> = object({
  field: string().required("Field is required"),
  isRequired: boolean().required("This field is required"),
  column: string().when("isRequired", (value, schema) => {
    if (value === true) {
      return schema.required("Column is required");
    }

    return schema;
  }),
});

const dataExtensionsColumnsSchema: SchemaOf<DataExtensionAttributes> = object({
  fileId: string().required("File ID is required"),
  columns: array().of(dataExtensionColumn),
});

// @ts-ignore
export const salesforceSchema: SchemaOf<SalesforceType> = object({
  clientId: string().required(requiredFieldText),
  clientSecret: string().required(requiredFieldText),
  origin: string().required(requiredFieldText),
  authOrigin: string().required(requiredFieldText),
  soapOrigin: string().required(requiredFieldText),
  type: string().oneOf(["dataExtension", "list"]).required(requiredFieldText),
  listId: string().required(requiredFieldText),
  custom_attributes: array()
    .when("type", (type, schema) => {
      if (type === "list") return schema.of(listsColumnsSchema).required();
      return schema.of(dataExtensionsColumnsSchema).required();
    })
    .min(1, "Custom attributes are required"),
});
