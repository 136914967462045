import React, { useState } from "react";
import {
  Button,
  Search,
  Table,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { getInitialStorageFilters } from "utils";
import { RemarketyIntegration } from "models";
import { usePayload, useTablePagination } from "hooks";
import { getIntegrations } from "api";
import { IntegrationsQuery } from "../integrations-list.types";
import { UpdateRemarkety } from "./update/update-remarkety";
import { initialFilters, columns } from "./remarkety-table.constants";
import { RemarketyItem } from "./item/remarkety-item";
import { CreateRemarkety } from "./create/create-remarkety";

import styles from "../integrations-list.module.scss";

export const RemarketyTable: React.FC = () => {
  const { query, updateQueryParams, queryString, searchValue, setSearch } =
    useFilters<IntegrationsQuery>(
      getInitialStorageFilters<IntegrationsQuery>("remarkety-integrations", initialFilters),
    );

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<RemarketyIntegration | null>(null);

  const remarketyResponse = useFetch(getIntegrations.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, error, refresh } = usePayload(remarketyResponse);

  const pagination = usePagination({ listPayload: payload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: "remarkety-integrations",
  });

  return (
    <div>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />

        <Button onClick={() => setCreateOpen(true)}>Create Remarkety integration</Button>
      </div>

      <Table
        columns={columns}
        list={payload?.data || []}
        loading={loading}
        row={(item) => (
          <RemarketyItem
            item={item as RemarketyIntegration}
            refresh={refresh}
            handleEditOpen={() => setEditedItem(item as RemarketyIntegration)}
          />
        )}
        error={error?.message}
        resetColumnsOnMount={false}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
      />

      <CreateRemarkety
        isOpen={createOpen}
        handleClose={() => setCreateOpen(false)}
        onSuccess={refresh}
      />

      {editedItem && (
        <UpdateRemarkety
          isOpen={!!editedItem}
          item={editedItem}
          handleClose={() => setEditedItem(null)}
          onSuccess={refresh}
        />
      )}
    </div>
  );
};
