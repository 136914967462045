import React, { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Label,
  notification,
  Select,
  SelectOption,
  Tooltip,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { MarketoFormField, MarketoIntegration } from "models";
import { getMarketoFields, getMarketoForms } from "api";
import { useConfigs } from "../hooks/use-configs/use-configs.hook";
import { MarketoType } from "./marketo.types";
import { MarketoColumns } from "./marketo-columns/marketo-columns";

import { Download } from "assets/images";
import mainStyles from "../connectors.module.css";
import styles from "../../job-form.module.css";

type MarketoProps = {
  endpointOrdinal: number;
};

const Marketo: FC<MarketoProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, setFieldTouched, submitCount } =
    useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig } = useConfigs<MarketoIntegration>("marketo");

  const [formOptions, setFormOptions] = useState<SelectOption<number>[]>([]);
  const [formFields, setFormFields] = useState<MarketoFormField[]>([]);

  const marketoValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | MarketoType
    | undefined;

  const { clientId, clientSecret, endpoint, formId } = marketoValues || {};

  const getFormsCall = useCall(getMarketoForms);
  getFormsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: SelectOption<number>[] = payload.map(({ formId, name }) => ({
      value: formId,
      label: name,
    }));

    notification.success("Data extensions fetched!", "Select your data extension.");
    setFormOptions(optionsToSave);
  });
  getFormsCall.onCallError(() => {
    notification.error("Fetching error", "Some error happened, please try again");
  });

  const getMarketoFieldsCall = useCall(getMarketoFields);
  getMarketoFieldsCall.onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    setFormFields(payload);
  });
  getMarketoFieldsCall.onCallError(() => {
    notification.error("Fetching error", "Could not fetch data extension fields, please try again");
  });

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.clientId`, config.properties.clientId);
      await setFieldValue(`${propertiesField}.clientSecret`, config.properties.clientSecret);
      await setFieldValue(`${propertiesField}.endpoint`, config.properties.endpoint);
    }
  };

  const fetchDisabled = !clientId || !clientSecret || !endpoint || getFormsCall.submitting;

  const handleGetFormsClick = async () => {
    setFormOptions([]);

    await getFormsCall.submit({
      data: {
        clientId: clientId?.trim(),
        clientSecret: clientSecret?.trim(),
        endpoint: endpoint?.trim(),
      },
    });
  };

  const handleFormSelect = async (option: SelectOption<number>) => {
    await setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.formId`,
      option?.value || "",
    );
    // sync validation
    setTimeout(() =>
      setFieldTouched(`endpoints[${endpointOrdinal}].connector.properties.formId`, true),
    );

    if (option && !fetchDisabled) {
      await getMarketoFieldsCall.submit({
        data: {
          clientId,
          clientSecret,
          endpoint,
          formId: option.value,
        },
      });
    }
  };

  return (
    <>
      <div className={mainStyles.configGrid}>
        <Label text="Load Marketo config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          isSearchable
          inputSize="small"
          searchPlaceholder="Search config"
          inputClassName={mainStyles.configSelect}
          disableError
          disableClearing
          placeholder="Select Marketo config"
          asyncOptions={{
            loading,
          }}
        />
      </div>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.endpoint`}
        label="Endpoint"
        placeholder="Endpoint"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientSecret`}
        label="Client secret"
        placeholder="Client secret"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientId`}
        label="Client ID"
        placeholder="Client ID"
      />

      <div className={styles.listWrapper}>
        <Select
          label="Form"
          placeholder="Select form"
          name={`endpoints[${endpointOrdinal}].connector.properties.formId`}
          searchPlaceholder="Search form"
          selectedOptionsKeys={formId}
          options={formOptions}
          onChange={handleFormSelect}
          disabled={fetchDisabled}
          isSearchable
          asyncOptions={{
            loading: getFormsCall.submitting,
          }}
        />
        <Tooltip
          trigger="hover"
          triggerElement={
            <Button
              disabled={fetchDisabled}
              onClick={handleGetFormsClick}
              className={styles.btnDataExtensions}
            >
              <Download />
            </Button>
          }
        >
          Get forms
        </Tooltip>
      </div>

      {!!formId && formFields.length > 0 && (
        <MarketoColumns
          endpointOrdinal={endpointOrdinal}
          fields={formFields}
          fieldsLoading={getMarketoFieldsCall.submitting}
        />
      )}
    </>
  );
};

export { Marketo };
