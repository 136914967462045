import { GreenArrowData, IntegrationData } from "api";

export const initialValues: IntegrationData<GreenArrowData> = {
  apiType: "greenarrow",
  name: "",
  properties: {
    apiKey: "",
    apiUrl: "",
  },
};
