import { SalesforceIntegration } from "models";
import { IntegrationData, SalesforceData } from "api";

export const initialValues = (item: SalesforceIntegration): IntegrationData<SalesforceData> => ({
  apiType: "salesforce",
  name: item?.name || "",
  properties: {
    clientId: item?.properties.clientId || "",
    clientSecret: item?.properties.clientSecret || "",
    origin: item?.properties.origin || "",
    authOrigin: item?.properties.authOrigin || "",
    soapOrigin: item?.properties.soapOrigin || "",
  },
});
