import { object, SchemaOf, string, mixed } from "yup";
import { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, Select } from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { BlueshiftIntegration, SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { useConfigs } from "./hooks/use-configs/use-configs.hook";

import styles from "./connectors.module.css";

export const blueshiftSchema: SchemaOf<BlueshiftType> = object({
  apiKey: string().required(requiredFieldText),
  custom_attributes: mixed()
    .required(requiredFieldText)
    .test("json", "JSON is invalid", (value) => testJSONValidity(value)),
});

export type BlueshiftType = {
  apiKey: string;
  custom_attributes: string;
};

type BlueshiftProps = {
  endpointOrdinal: number;
};

const Blueshift: FC<BlueshiftProps> = ({ endpointOrdinal }) => {
  const { setFieldValue } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig } = useConfigs<BlueshiftIntegration>("blueshift");

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
    }
  };

  return (
    <>
      <div className={styles.configGrid}>
        <Label text="Load Blueshift config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          isSearchable
          searchPlaceholder="Search config"
          disableClearing
          inputSize="small"
          inputClassName={styles.configSelect}
          placeholder="Select Blueshift config"
          disableError
          asyncOptions={{
            loading,
          }}
        />
      </div>

      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="Customer Data"
        className={styles.headersLabel}
      />
    </>
  );
};

export { Blueshift };
