import React, { useState } from "react";
import {
  Button,
  Search,
  Table,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { getInitialStorageFilters } from "utils";
import { MarketoIntegration } from "models";
import { usePayload, useTablePagination } from "hooks";
import { getIntegrations } from "api";
import { IntegrationsQuery } from "../integrations-list.types";
import { UpdateMarketo } from "./update/update-marketo";
import { initialFilters, columns } from "./marketo-table.constants";
import { MarketoItem } from "./item/marketo-item";
import { CreateMarketo } from "./create/create-marketo";

import styles from "../integrations-list.module.scss";

export const MarketoTable: React.FC = () => {
  const { query, updateQueryParams, queryString, searchValue, setSearch } =
    useFilters<IntegrationsQuery>(
      getInitialStorageFilters<IntegrationsQuery>("marketo-integrations", initialFilters),
    );

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<MarketoIntegration | null>(null);

  const marketoResponse = useFetch(getIntegrations.setQueryParams(queryString), {
    dependencies: [queryString],
  });
  const { payload, loading, error, refresh } = usePayload(marketoResponse);

  const pagination = usePagination({ listPayload: payload });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: "marketo-integrations",
  });

  return (
    <div>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} className={styles.search} />

        <Button onClick={() => setCreateOpen(true)}>Create Marketo integration</Button>
      </div>

      <Table
        columns={columns}
        list={payload?.data || []}
        loading={loading}
        row={(item) => (
          <MarketoItem
            item={item as MarketoIntegration}
            refresh={refresh}
            handleEditOpen={() => setEditedItem(item as MarketoIntegration)}
          />
        )}
        error={error?.message}
        resetColumnsOnMount={false}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
      />

      <CreateMarketo
        isOpen={createOpen}
        handleClose={() => setCreateOpen(false)}
        onSuccess={refresh}
      />

      {editedItem && (
        <UpdateMarketo
          isOpen={!!editedItem}
          item={editedItem}
          handleClose={() => setEditedItem(null)}
          onSuccess={refresh}
        />
      )}
    </div>
  );
};
