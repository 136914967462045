import { MarketoIntegration } from "models";
import { IntegrationData, MarketoData } from "api";

export const initialValues = (item: MarketoIntegration): IntegrationData<MarketoData> => ({
  apiType: "marketo",
  name: item?.name || "",
  properties: {
    endpoint: item?.properties.endpoint || "",
    clientId: item?.properties.clientId || "",
    clientSecret: item?.properties.clientSecret || "",
  },
});
