import { SchemaOf } from "yup";
import { FC, ReactElement } from "react";

import { EndpointAPITypesKeys } from "models";
import {
  UseInsider,
  useInsiderSchema,
  UseInsiderType,
} from "../connectors/use-insider/use-insider";
import { Salesforce, salesforceSchema, SalesforceType } from "../connectors/salesforce";
import { RestApi, restAPISchema, RestAPIType } from "../connectors/rest-api";
import { Remarkety, remarketySchema, RemarketyType } from "../connectors/remarkety";
import { Omnisend, omnisendSchema, OmnisendType } from "../connectors/omnisend";
import { Marketo, marketoSchema, MarketoType } from "../connectors/marketo";
import { Iterable, iterableSchema, IterableType } from "../connectors/iterable";
import { GreenArrow, greenArrowSchema, GreenArrowType } from "../connectors/green-arrow";
import {
  GetResponse,
  getResponseSchema,
  GetResponseType,
} from "../connectors/get-response/get-response";
import { Blueshift, blueshiftSchema, BlueshiftType } from "../connectors/blueshift";

export type EndpointAPIContentType =
  | SalesforceType
  | RestAPIType
  | OmnisendType
  | RemarketyType
  | BlueshiftType
  | IterableType
  | GreenArrowType
  | UseInsiderType
  | GetResponseType
  | MarketoType;

type ConnectorsValidationsMap = Record<EndpointAPITypesKeys, SchemaOf<EndpointAPIContentType>>;

export const ConnectorsValidations: ConnectorsValidationsMap = {
  salesforce: salesforceSchema,
  restApi: restAPISchema,
  omnisend: omnisendSchema,
  remarkety: remarketySchema,
  blueshift: blueshiftSchema,
  iterable: iterableSchema,
  greenarrow: greenArrowSchema,
  useInsider: useInsiderSchema,
  getResponse: getResponseSchema,
  marketo: marketoSchema,
};

type EndpointAPIDispatcherProps = {
  apiType: EndpointAPITypesKeys;
  endpointOrdinal: number;
  handleDisableSubmit: (valid: boolean) => void;
  isInReconfiguration: boolean;
};

type DispatchType = Record<EndpointAPITypesKeys, ReactElement>;

const EndpointAPIDispatcher: FC<EndpointAPIDispatcherProps> = ({ apiType, ...rest }) => {
  const APIMapDispatch: DispatchType = {
    salesforce: <Salesforce {...rest} />,
    restApi: <RestApi {...rest} />,
    omnisend: <Omnisend {...rest} />,
    remarkety: <Remarkety {...rest} />,
    blueshift: <Blueshift {...rest} />,
    iterable: <Iterable {...rest} />,
    greenarrow: <GreenArrow {...rest} />,
    useInsider: <UseInsider {...rest} />,
    getResponse: <GetResponse {...rest} />,
    marketo: <Marketo {...rest} />,
  };

  return APIMapDispatch[apiType];
};

export { EndpointAPIDispatcher };
