import { FormikErrors } from "formik";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { AvailableFileModel, MarketoFormField } from "models";
import { MarketoColumnAttribute, MarketoColumn, MarketoType } from "../marketo.types";

export const getInitialMarketoColumns = (
  files: AvailableFileModel[],
  fields: MarketoFormField[],
): MarketoColumnAttribute[] => {
  return files.map((file) => {
    const columns: MarketoColumn[] = fields.map((field) => ({
      field: field.formFieldId,
      column: "",
      isRequired: field.isRequired,
    }));

    return { fileId: file.fileId, columns: columns };
  });
};

export const haveColumnsError = (
  errors: FormikErrors<InitialValues>,
  endpointOrdinal: number,
  fileIndex: number,
) => {
  const endpointObject = errors?.endpoints?.[endpointOrdinal];

  if (typeof endpointObject === "string") return false;

  const properties = endpointObject?.connector?.properties as unknown as MarketoType;

  return typeof properties?.files?.[fileIndex] !== "undefined";
};
