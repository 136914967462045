import { GreenArrowIntegration } from "models";
import { GreenArrowData, IntegrationData, IterableData } from "api";

export const initialValues = (item: GreenArrowIntegration): IntegrationData<GreenArrowData> => ({
  apiType: "greenarrow",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
    apiUrl: item?.properties.apiUrl || "",
  },
});
