import { IntegrationData, UseInsiderData } from "api";

export const initialValues: IntegrationData<UseInsiderData> = {
  name: "",
  apiType: "useInsider",
  properties: {
    apiKey: "",
    partnerName: "",
  },
};
