import { List } from "models";
import { apiMiddleware } from "api/api.middleware";
import { IntegrationsResponse, IntegrationData, IntegrationsData } from "./integrations.types";

export const getIntegrations = apiMiddleware<List<IntegrationsResponse>>()({
  method: "get",
  endpoint: "/integrations",
});

export const createIntegration = apiMiddleware<
  IntegrationsResponse,
  IntegrationData<IntegrationsData>
>()({
  method: "post",
  endpoint: "/integrations",
});

export const updateIntegration = apiMiddleware<
  IntegrationsResponse,
  IntegrationData<IntegrationsData>
>()({
  method: "put",
  endpoint: "/integrations/:id",
});

export const deleteIntegration = apiMiddleware<{ id: number }>()({
  method: "delete",
  endpoint: "/integrations/:id",
});
