import { OmnisendIntegration } from "models";
import { IntegrationData, OmnisendData } from "api";

export const initialValues = (item: OmnisendIntegration): IntegrationData<OmnisendData> => ({
  apiType: "omnisend",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
  },
});
