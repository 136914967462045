import { GetResponseIntegration } from "models";
import { GetResponseData, IntegrationData } from "api";

export const initialValues = (item: GetResponseIntegration): IntegrationData<GetResponseData> => ({
  name: item?.name || "",
  apiType: "getResponse",
  properties: {
    apiKey: item?.properties.apiKey || "",
  },
});
