import React from "react";
import cn from "classnames";
import { TextEllipsis, Tooltip } from "@epcnetwork/core-ui-kit";

import { Check, Exclamation, File } from "assets/images";

import styles from "components/ui/file-tabs/file-tabs.module.scss";

interface Props {
  selected: boolean;
  label: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  isValid?: boolean;
}

export const FileTabButton: React.FC<Props> = ({
  selected,
  label,
  disabled,
  onClick,
  className,
  isValid,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(
        styles.button,
        { [styles.active]: selected, [styles.invalid]: !isValid },
        className,
      )}
      disabled={disabled}
    >
      <div className={styles.row}>
        <File className={styles.icon} />
        <TextEllipsis>
          <span>{label}</span>
        </TextEllipsis>
      </div>
      {isValid ? (
        <Check className={styles.iconSuccess} />
      ) : (
        <Tooltip triggerElement={<Exclamation className={styles.iconError} />} trigger="hover">
          One or more validation errors occurred
        </Tooltip>
      )}
    </button>
  );
};
