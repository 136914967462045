import React from "react";
import cn from "classnames";

import { TabOption } from "components/ui/tabs";

import styles from "./underlined-tabs.module.scss";

interface Props<T extends string | number | boolean> {
  selected: TabOption<T>["value"];
  onChange: (option: TabOption<T>, index: number) => void;
  options: TabOption<T>[];
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
}

export function UnderlinedTabs<T extends string | number | boolean>({
  options,
  selected,
  onChange,
  className,
  disabled,
  buttonClassName,
}: Props<T>) {
  return (
    <div className={cn(styles.container, className)}>
      {options.map((option, index) => {
        return (
          <button
            key={option.value.toString()}
            type="button"
            onClick={() => onChange(option, index)}
            className={cn(
              styles.button,
              { [styles.active]: option.value === selected },
              buttonClassName,
              option?.className,
            )}
            disabled={option?.disabled || disabled}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
}
