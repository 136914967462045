import React, { useMemo, useState } from "react";
import { useFormikContext } from "formik";
import {
  FormField,
  Label,
  Loader,
  SelectOption,
  useDidMount,
  useDidUpdate,
} from "@epcnetwork/core-ui-kit";

import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { essentialColumn } from "pages/files/list/files-list.constants";
import { MarketoFormField } from "models";
import { FileTabButton } from "components/ui/file-tabs";
import { TabOption } from "components";
import { MarketoType } from "../marketo.types";
import { getInitialMarketoColumns, haveColumnsError } from "./marketo-columns.constants";

import styles from "./marketo-columns.module.scss";

interface Props {
  endpointOrdinal: number;
  fields: MarketoFormField[];
  fieldsLoading: boolean;
}

export const MarketoColumns: React.FC<Props> = ({ endpointOrdinal, fields, fieldsLoading }) => {
  const [editedFileIndex, setEditedFileIndex] = useState<number>(0);

  const { values, errors, setFieldValue } = useFormikContext<InitialValues>();

  const marketoValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | MarketoType
    | undefined;
  const { files } = marketoValues || {};

  const getFieldName = (index: number) => (suffix: string) =>
    `endpoints[${endpointOrdinal}].connector.properties.files[${index}]${suffix}`;

  useDidMount(() => {
    if (files?.length === 0) {
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties.files`,
        getInitialMarketoColumns(values.attachedFiles, fields),
      );
    }
  });

  useDidUpdate(() => {
    // set new values when files change
    setEditedFileIndex(0);
    setFieldValue(
      `endpoints[${endpointOrdinal}].connector.properties.files`,
      getInitialMarketoColumns(values.attachedFiles, fields),
    );
  }, [values.attachedFiles]);

  const fileTabOptions: TabOption<number>[] = useMemo(() => {
    return values.attachedFiles.map((file, index) => ({ label: file.fileName, value: index }));
  }, [values.attachedFiles]);

  const fileColumns: SelectOption<string>[] = useMemo(() => {
    if (!values.attachedFiles?.[editedFileIndex]) return [];

    const emailKey = essentialColumn.toLowerCase();
    const emailColumn: SelectOption<string> = { label: emailKey, value: emailKey };
    const columns: SelectOption<string>[] = values.attachedFiles?.[editedFileIndex]?.columns.map(
      (column) => ({
        label: column,
        value: column,
      }),
    );

    return [emailColumn, ...columns];
  }, [values.attachedFiles, editedFileIndex]);

  const haveFilesSelected = values.attachedFiles.length > 0;

  return (
    <div>
      <p className={styles.mainLabel}>Form columns</p>
      <p className={styles.description}>Select columns for each file</p>
      {!haveFilesSelected && (
        <p className={styles.description}>You need to select a file to set a columns</p>
      )}
      {haveFilesSelected && (
        <>
          {fieldsLoading && <Loader type="clip-loader" />}
          {!fieldsLoading && fields.length === 0 && (
            <p className={styles.description}>No fields found for this form</p>
          )}
          {!fieldsLoading && fields.length > 0 && (
            <div>
              <div className={styles.tabsWrapper}>
                {fileTabOptions.map((option, index) => {
                  const haveError = haveColumnsError(errors, endpointOrdinal, index);

                  return (
                    <FileTabButton
                      key={option.value}
                      selected={option.value === editedFileIndex}
                      label={option.label}
                      isValid={!haveError}
                      onClick={() => setEditedFileIndex(index)}
                    />
                  );
                })}
              </div>

              <div className={styles.content}>
                {fields.map((field, index) => {
                  return (
                    <div key={field.formFieldId} className={styles.inputWrapper}>
                      <div className={styles.inputLabel}>
                        <Label text={field.label} isInputLabel isRequired={field.isRequired} />
                        <p className={styles.projectDescription}>
                          Select "{field.formFieldId}" column
                        </p>
                      </div>
                      <FormField
                        type="select"
                        label=""
                        required
                        inputSize="small"
                        placeholder="Select column"
                        name={getFieldName(editedFileIndex)(`.columns[${index}].column`)}
                        options={fileColumns}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
