import { object, string, SchemaOf } from "yup";
import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { defaultLimit, defaultOffset } from "constants/query.constants";
import { requiredFieldText, searchMinLength } from "constants/form.constants";
import { IntegrationData, GetResponseData } from "api";
import { IntegrationsQuery } from "../integrations-list.types";

export const initialFilters: UseFiltersProps<IntegrationsQuery> = {
  searchOptions: { searchKey: "search", searchMinLength },
  initialState: { search: "", limit: defaultLimit, offset: defaultOffset, apiType: "getResponse" },
};

export const columns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "API Key" },
  { label: "" },
];

// @ts-ignore
export const getResponseSchema: SchemaOf<IntegrationData<GetResponseData>> = object({
  apiType: string().required(requiredFieldText),
  name: string().required(requiredFieldText),
  properties: object().shape({
    apiKey: string().required(requiredFieldText),
  }),
});
