import React, { useState } from "react";

import { EndpointAPITypesKeys } from "models";
import { Container, TabOption, UnderlinedTabs } from "components";
import { UseInsiderTable } from "./use-insider/use-insider-table";
import { SalesforceTable } from "./salesforce/salesforce-table";
import { RemarketyTable } from "./remarkety/remarkety-table";
import { OmnisendTable } from "./omnisend/omnisend-table";
import { MarketoTable } from "./marketo/marketo-table";
import { IterableTable } from "./iterable/iterable-table";
import { GreenArrowTable } from "./green-arrow/green-arrow-table";
import { GetResponseTable } from "./get-response/get-response-table";
import { BlueshiftTable } from "./blueshift/blueshift-table";

import styles from "./integrations-list.module.scss";

export const IntegrationsListPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<EndpointAPITypesKeys>("iterable");

  const tabsOptions: TabOption<EndpointAPITypesKeys>[] = [
    { label: "Iterable", value: "iterable" },
    { label: "Salesforce", value: "salesforce" },
    { label: "GreenArrow", value: "greenarrow" },
    { label: "UseInsider", value: "useInsider" },
    { label: "GetResponse", value: "getResponse" },
    { label: "Blueshift", value: "blueshift" },
    { label: "Omnisend", value: "omnisend" },
    { label: "Remarkety", value: "remarkety" },
    { label: "Marketo", value: "marketo" },
  ];

  const onTabChange = (tab: TabOption<EndpointAPITypesKeys>) => {
    setCurrentTab(tab.value);
  };

  return (
    <Container>
      <UnderlinedTabs
        selected={currentTab}
        onChange={onTabChange}
        options={tabsOptions}
        className={styles.tabs}
      />

      <div className={styles.content}>
        {currentTab === "iterable" && <IterableTable />}
        {currentTab === "salesforce" && <SalesforceTable />}
        {currentTab === "greenarrow" && <GreenArrowTable />}
        {currentTab === "useInsider" && <UseInsiderTable />}
        {currentTab === "getResponse" && <GetResponseTable />}
        {currentTab === "blueshift" && <BlueshiftTable />}
        {currentTab === "omnisend" && <OmnisendTable />}
        {currentTab === "remarkety" && <RemarketyTable />}
        {currentTab === "marketo" && <MarketoTable />}
      </div>
    </Container>
  );
};
