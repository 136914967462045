import { IntegrationData, RemarketyData } from "api";

export const initialValues: IntegrationData<RemarketyData> = {
  apiType: "remarkety",
  name: "",
  properties: {
    apiKey: "",
    storeId: "",
  },
};
