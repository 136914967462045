import { UseInsiderIntegration } from "models";
import { IntegrationData, UseInsiderData } from "api";

export const initialValues = (item: UseInsiderIntegration): IntegrationData<UseInsiderData> => ({
  apiType: "useInsider",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
    partnerName: item?.properties.partnerName || "",
  },
});
