import { FC } from "react";

import { AuthorizationActionType, AuthorizationEntityNameType } from "models";
import {
  JOBS_PERMISSIONS,
  USERS_PERMISSIONS,
  EMAILS_SUPPRESSION_PERMISSIONS,
  INTEGRATIONS_PERMISSIONS,
} from "constants/roles.constants";

export interface RouteConfig<ComponentType extends FC = FC, Path extends string = string> {
  path: Path;
  component: ComponentType;
  name: string;
  exact: boolean;
  auth: boolean;
  showNavigation: boolean;
  authEntity: AuthorizationEntityNameType | null;
  authAction: AuthorizationActionType | null;
  pageNameTooltipText?: string;
}

type PageType<C extends FC = FC, P extends string = string> = Omit<RouteConfig<C, P>, "component">;

// FILES
export const UPLOAD_LIST_PAGE: PageType = {
  path: "/upload",
  name: "Upload",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: null,
  authEntity: null,
} as const;

// TAGS
export const TAGS_LIST_PAGE: PageType = {
  path: "/tags",
  name: "Tag manager",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "read",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

// INTEGRATIONS
export const INTEGRATIONS_LIST_PAGE = {
  path: "/integrations",
  name: "Integrations",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: INTEGRATIONS_PERMISSIONS.name,
} as const;

// JOBS
export const JOBS_LIST_PAGE = {
  path: "/jobs",
  name: "Jobs",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "read",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

export const JOBS_FORM_PAGE: PageType = {
  path: "/jobs/add",
  name: "Create Batch",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: JOBS_PERMISSIONS.name,
  pageNameTooltipText: "Batch is a set of the created jobs (1-10)",
} as const;

export const JOBS_DETAILS_PAGE: PageType<FC, "/jobs/:jobId"> = {
  path: "/jobs/:jobId",
  name: "Jobs in details",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "read",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

export const JOBS_FORM_EDIT_PAGE: PageType<FC, "/job/edit/:jobId"> = {
  path: "/job/edit/:jobId",
  name: "Job Editing",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

export const JOBS_FORM_RECONFIGURATION_PAGE: PageType<FC, "/job/configure/:jobId"> = {
  path: "/job/configure/:jobId",
  name: "Reconfiguration job",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

// ALLOCATION
export const ALLOCATION_PAGE: PageType = {
  path: "/allocation",
  name: "Allocation",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: null,
  authEntity: null,
} as const;
export const ALLOCATION_FORM_PAGE: PageType<FC, "/allocation/configure/:batchId"> = {
  path: "/allocation/configure/:batchId",
  name: "Allocation",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: JOBS_PERMISSIONS.name,
} as const;
export const ALLOCATION_EDIT_PAGE: PageType<FC, "/allocation/edit/:batchId"> = {
  path: "/allocation/edit/:batchId",
  name: "Batch Editing",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "create",
  authEntity: JOBS_PERMISSIONS.name,
} as const;

// EMAIL_SUPPRESSION
export const EMAILS_SUPPRESSION_LIST_PAGE: PageType = {
  path: "/emails-suppression",
  name: "Emails Suppression",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "read",
  authEntity: EMAILS_SUPPRESSION_PERMISSIONS.name,
} as const;

// USERS
export const USERS_LIST_PAGE: PageType = {
  path: "/users",
  name: "Users",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: "read",
  authEntity: USERS_PERMISSIONS.name,
} as const;
export const MY_ACCOUNT_PAGE: PageType = {
  path: "/account/me",
  name: "My account",
  exact: true,
  auth: true,
  showNavigation: true,
  authAction: null,
  authEntity: null,
} as const;

// ---
export const DEFAULT_PAGE: PageType = {
  ...UPLOAD_LIST_PAGE,
  path: "/",
} as const;

// AUTH
export const LOGIN_PAGE: PageType = {
  path: "/login",
  name: "Login",
  exact: true,
  auth: false,
  showNavigation: false,
  authAction: null,
  authEntity: null,
} as const;
export const LOGOUT_PAGE: PageType = {
  path: "/logout",
  name: "Logout",
  exact: true,
  auth: false,
  showNavigation: false,
  authAction: null,
  authEntity: null,
} as const;
export const FORGOT_PASSWORD_PAGE: PageType = {
  path: "/forgot-password",
  name: "Forgot Password",
  exact: true,
  auth: false,
  showNavigation: false,
  authAction: null,
  authEntity: null,
} as const;
export const RESET_PASSWORD_PAGE: PageType = {
  path: "/reset-password",
  name: "Reset Password",
  exact: true,
  auth: false,
  showNavigation: false,
  authAction: null,
  authEntity: null,
} as const;

export const NOT_FOUND_PAGE: PageType = {
  path: "*",
  name: "Not Found",
  exact: false,
  auth: false,
  showNavigation: false,
  authAction: null,
  authEntity: null,
} as const;
