import { BlueshiftIntegration } from "models";
import { BlueshiftData, IntegrationData } from "api";

export const initialValues = (item: BlueshiftIntegration): IntegrationData<BlueshiftData> => ({
  apiType: "blueshift",
  name: item?.name || "",
  properties: {
    apiKey: item?.properties.apiKey || "",
  },
});
