import React from "react";
import { Formik, Form } from "formik";
import { Button, FormField, Modal, notification, useCall } from "@epcnetwork/core-ui-kit";

import { createIntegration, IntegrationData, MarketoData } from "api";
import { marketoSchema } from "../marketo-table.constants";
import { initialValues } from "./create-marketo.constants";

import styles from "../marketo-table.module.scss";

interface CreateMarketoProps {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

export const CreateMarketo: React.FC<CreateMarketoProps> = ({ isOpen, handleClose, onSuccess }) => {
  const { submit, onCallSuccess, onCallError } = useCall(createIntegration);
  onCallSuccess(() => {
    onSuccess();
    notification.success(
      "Marketo config created.",
      "You can now use this config to create a new job.",
    );
    handleClose();
  });
  onCallError((error) => {
    notification.error(
      "Could not save this integration.",
      "There was an error with creating Marketo integration. " + error?.message,
    );
  });

  const handleSubmit = async (values: IntegrationData<MarketoData>) => {
    await submit({ data: values, queryParams: "?apiType=marketo" });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <div className={styles.header}>
        <h2>Create Marketo config</h2>
        <p>Create config for Marketo. This config can be used to create a new job later.</p>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={marketoSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormField
                type="text"
                name="name"
                label="Config name"
                placeholder="Provide a config name"
              />
              <FormField
                type="text"
                name="properties.endpoint"
                label="Endpoint"
                placeholder="Endpoint"
              />
              <FormField
                type="text"
                name="properties.clientSecret"
                label="Client secret"
                placeholder="Client secret"
              />
              <FormField
                type="text"
                name="properties.clientId"
                label="Client ID"
                placeholder="Client ID"
              />

              <div className={styles.buttons}>
                <Button appearance="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
