import { FC } from "react";
import { useDevice, useWindowSize } from "@epcnetwork/core-ui-kit";

import { AuthorizationActionType, AuthorizationEntityNameType } from "models";
import { useAuthorization } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import {
  ALLOCATION_PAGE,
  INTEGRATIONS_LIST_PAGE,
  JOBS_FORM_PAGE,
  JOBS_LIST_PAGE,
  TAGS_LIST_PAGE,
  UPLOAD_LIST_PAGE,
  USERS_LIST_PAGE,
} from "constants/routes.constants";
import {
  INTEGRATIONS_PERMISSIONS,
  JOBS_PERMISSIONS,
  USERS_PERMISSIONS,
} from "constants/roles.constants";
import { Link } from "./link";

import usersIcon from "assets/images/menu-users.svg";
import uploadIcon from "assets/images/menu-upload.svg";
import tagsIcon from "assets/images/menu-tag.svg";
import jobsIcon from "assets/images/menu-jobs.svg";
import endpointIcon from "assets/images/menu-endpoint.svg";
import allocationIcon from "assets/images/menu-allocation.svg";
import layersIcon from "assets/images/layers.svg";
import styles from "../sidebar.module.css";

type LinksType = {
  src: string;
  text: string;
  to: string;
  authAction?: AuthorizationActionType;
  authEntity?: AuthorizationEntityNameType;
  exact?: boolean;
  mobile?: boolean;
};

type MenuProps = {
  closeSidebarMobile: () => void;
};

const Menu: FC<MenuProps> = ({ closeSidebarMobile }) => {
  const ability = useAuthorization();
  const { width } = useWindowSize();
  const { isMobileDevice } = useDevice();

  const isMobile = width < MAX_MOBILE_WIDTH || isMobileDevice;

  const permittedLinks = links.filter((link) => {
    const { mobile, authEntity, authAction } = link;
    if (mobile === false && isMobile) {
      return false;
    }
    if (authEntity && authAction) {
      return ability.can(authAction, authEntity);
    }
    return true;
  });

  return (
    <div className={styles.menu}>
      {permittedLinks.map((link) => (
        // TODO: sidebar doesn't close if 'closeSidebarMobile' added to 'Link' directly
        <span key={link.text} onClick={closeSidebarMobile}>
          <Link {...link} />
        </span>
      ))}
    </div>
  );
};

export { Menu };

const links: LinksType[] = [
  {
    src: uploadIcon,
    text: UPLOAD_LIST_PAGE.name,
    to: UPLOAD_LIST_PAGE.path,
    exact: true,
  },
  {
    src: tagsIcon,
    text: TAGS_LIST_PAGE.name,
    to: TAGS_LIST_PAGE.path,
  },
  {
    src: layersIcon,
    text: INTEGRATIONS_LIST_PAGE.name,
    to: INTEGRATIONS_LIST_PAGE.path,
    authAction: "create",
    authEntity: INTEGRATIONS_PERMISSIONS.name,
  },
  {
    src: endpointIcon,
    text: JOBS_FORM_PAGE.name,
    to: JOBS_FORM_PAGE.path,
    authAction: "create",
    authEntity: JOBS_PERMISSIONS.name,
    mobile: false,
  },
  {
    src: allocationIcon,
    text: ALLOCATION_PAGE.name,
    to: ALLOCATION_PAGE.path,
    authAction: "read",
    authEntity: JOBS_PERMISSIONS.name,
  },
  {
    src: jobsIcon,
    text: JOBS_LIST_PAGE.name,
    to: JOBS_LIST_PAGE.path,
  },
  {
    src: usersIcon,
    text: USERS_LIST_PAGE.name,
    to: USERS_LIST_PAGE.path,
    authAction: "read",
    authEntity: USERS_PERMISSIONS.name,
  },
];
