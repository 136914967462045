import { IntegrationData, SalesforceData } from "api";

export const initialValues: IntegrationData<SalesforceData> = {
  apiType: "salesforce",
  name: "",
  properties: {
    clientId: "",
    clientSecret: "",
    origin: "",
    authOrigin: "",
    soapOrigin: "",
  },
};
