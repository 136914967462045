import { object, SchemaOf, string, mixed } from "yup";
import { FC } from "react";
import { useFormikContext } from "formik";
import { FormField, Label, Select, SelectOption } from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { InitialValues } from "pages/jobs/form/jobs-form.types";
import { IterableIntegration } from "models";
import { requiredFieldText } from "constants/form.constants";
import { useConfigs } from "./hooks/use-configs/use-configs.hook";

import styles from "./connectors.module.css";

export const iterableSchema: SchemaOf<IterableType> = object({
  apiKey: string().required(requiredFieldText),
  listId: string().required(requiredFieldText),
  custom_attributes: mixed().test("json", "JSON is invalid", (value) =>
    typeof value !== "undefined" ? testJSONValidity(value) : true,
  ),
});

export type IterableType = {
  apiKey: string;
  listId: string;
  custom_attributes?: string;
};

type IterableProps = {
  endpointOrdinal: number;
};

const Iterable: FC<IterableProps> = ({ endpointOrdinal }) => {
  const { setFieldValue } = useFormikContext<InitialValues>();
  const { options, loading, getSelectedConfig } = useConfigs<IterableIntegration>("iterable");

  const handleConfigChange = async ({ value }: SelectOption<number>) => {
    const config = getSelectedConfig(value);
    const propertiesField = `endpoints[${endpointOrdinal}].connector.properties`;

    if (config) {
      await setFieldValue(`${propertiesField}.apiKey`, config.properties.apiKey);
    }
  };

  return (
    <>
      <div className={styles.configGrid}>
        <Label text="Load Iterable config" />
        <Select
          options={options}
          onChange={handleConfigChange}
          isSearchable
          inputSize="small"
          searchPlaceholder="Search config"
          inputClassName={styles.configSelect}
          disableError
          disableClearing
          placeholder="Select Iterable config"
          asyncOptions={{
            loading,
          }}
        />
      </div>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
        label="List ID"
        placeholder="List ID"
      />
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="Data fields (optional):"
        className={styles.headersLabel}
      />
    </>
  );
};

export { Iterable };
